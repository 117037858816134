export default function Participant({pName, state, missed, timeCritical}: {pName: string, state: string, missed: number, timeCritical: boolean}) {
	let stateStr = <i>not connected</i>;
	if(state === "Safe") {
		stateStr = <>✓ round complete</>;
	} else {
		stateStr = <>✗ round incomplete</>;
	}
	return <div className={"participant " + state + (timeCritical ? " critical" : "")}>
		<div className="participant-name">{pName}</div>
		<div className="participant-state">{stateStr}</div>
		<div className="participant-missed">{missed > 0 ? missed+" rounds missed" : "None missed"}</div>
	</div>;
}
