import React from 'react';
import Clock from './Clock';
import Participant from './Participant';
import { useEffect, useRef, useState, MutableRefObject } from 'react';

type ParticipantT = {
	name: string,
	state: string,
	total_missed: number,
};

type GameStateResponse = {
	current_round: number,
	players: Map<string, ParticipantT>,
};

function informServerOfNewRound(gamePin: string, newRound: number) {
	fetch("/api/state?" + new URLSearchParams( { pin: gamePin, round: ''+newRound } ), { 'method': 'POST' });
}

function doPoll(gamePin: string, round: MutableRefObject<number>, setPlayerStates: any) {
	fetch("/api/state?pin="+gamePin)
	 .then( (r) => r.json() )
	 .then( (j: GameStateResponse) => {
		if(j.current_round === round.current) {
			let ps = Object.values(j.players);
			setPlayerStates(ps);
		} else {
			console.log("received poll result from another round");
		}

		// set a fresh timeout in about a second
		window.setTimeout(doPoll, 950, gamePin, round, setPlayerStates);
	 } );
}

function getGamePin(setGamePin: any) {
	fetch("/api/new", { method: 'POST' })
	 .then( (r) => r.json() )
	 .then( (j: {pin?: string}) => { j && ("pin" in j) && setGamePin(j.pin) })
	 .catch( (e) => { console.log("getGamePin error: " + e); } );
}

function App() {
	const [currentMsec, setCurrentTime] = useState(0);
	const [startMsec, setStartTime] = useState(0);
	const [pauseStartMsec, setPauseStartMsec] = useState(0);
	const [isPaused, setIsPaused] = useState(false);
	const [gamePin, setGamePin] = useState("");
	const [playerStates, setPlayerStates] = useState<ParticipantT[]>([]);
	const [soundEnabled, setSoundEnabled] = useState(false);
	// Should we play the Last Second Save?
	const [lssEligible, setLssEligible] = useState(false);
	const currentRound = useRef(0);

	function toggleIsPaused() {
		if(isPaused) {
			// deal with this by adjusting the startTime
			setStartTime( startMsec + (Date.now() - pauseStartMsec) );
		} else {
			setPauseStartMsec(Date.now());
		}
		setIsPaused(!isPaused);
	}

	const roundLen = 60;
	const effectiveTime = isPaused ? pauseStartMsec : currentMsec;

	// this useEffect is for the timer and other setup
	useEffect( () => {
		setStartTime(Date.now());
		const tid = setInterval(() => {
			setCurrentTime(Date.now())
		}, 1000);

		getGamePin(setGamePin);

		return () => clearInterval(tid);
	}, []);

	const totalSecs = Math.round((effectiveTime - startMsec)/1000);
	const round = Math.max(1, 1+Math.floor(totalSecs / roundLen));
	const secInMinute = totalSecs % roundLen;
	const timeCritical = !!((secInMinute - roundLen + 5) > 0);
	const timeVeryCritical = !!((secInMinute - roundLen + 3) > 0);

	// figure out urgency
	const incompletePlayers = playerStates.filter( (p) => p.state === "AtRisk" ).length;

	if(currentRound.current !== round) {
		if(currentRound.current !== 0 && gamePin.length > 0) {
			// new round
			console.log("new round, so setting LSSE");
			setLssEligible(false); // insanely, this doesn't work
			informServerOfNewRound(gamePin, round);
		}
		currentRound.current = round; // update this so our poll timer can see it
	} else {
		if(timeVeryCritical && (incompletePlayers > 0) && !lssEligible) {
			console.log("I am setting LSS eligible");
			setLssEligible(true);
		}
	}


	// this useEffect manages polling the game state
	useEffect( () => {
		if(gamePin.length > 0) {
			// TODO: do we need to be more sophisticated about enabling this to
			// be cancelled?
			let tid = setTimeout(doPoll, 500, gamePin, currentRound, setPlayerStates);
			return () => clearTimeout(tid);
		}
	}, [gamePin] );

	// do audio if available
	useEffect( () => {
		if(!isPaused && soundEnabled) {
			const short_pip = document.getElementById('short-pip') as HTMLAudioElement;
			const long_pip = document.getElementById('long-pip') as HTMLAudioElement;
			const ut = [1,2,3,4,5].map( (i) => document.getElementById('ut-'+i) as HTMLAudioElement );
			const ut_lss = document.getElementById('ut-lss') as HTMLAudioElement;
			const secs_left = roundLen - secInMinute;
			if(long_pip && round > 1 && secInMinute === 0) {
				long_pip.play();
			} else if(short_pip && secs_left < 6) {
				if(incompletePlayers) {
					ut[secs_left-1].play();
				} else {
					short_pip.play();
				}
			}
			if(lssEligible && incompletePlayers === 0) {
				setLssEligible(false);
				if(timeVeryCritical) {
					ut_lss.play();
				}
			}
		}
		// V V V  -- in this dependency array we only have secInMinute; the
		// other things are all updated if sIM is
	}, [secInMinute] );

	if(currentMsec === 0) {
		return (<p>Setting up...</p>);
	} else {
		return (
			<div className="App">
				<audio id="short-pip" src="/audio/short-pip.mp3"></audio>
				<audio id="long-pip" src="/audio/long-pip.mp3"></audio>
				<audio id="ut-1" src="/audio/contrib/ut-1.mp3"></audio>
				<audio id="ut-2" src="/audio/contrib/ut-2.mp3"></audio>
				<audio id="ut-3" src="/audio/contrib/ut-3.mp3"></audio>
				<audio id="ut-4" src="/audio/contrib/ut-4.mp3"></audio>
				<audio id="ut-5" src="/audio/contrib/ut-5.mp3"></audio>
				<audio id="ut-lss" src="/audio/contrib/ut-last-second-reverb.mp3"></audio>
				<div className="round">Round {round}</div>
				<div className="controls"><button onClick={(e) => {e.preventDefault(); toggleIsPaused()}}>{isPaused ? " ⏵ Unpause" : " ⏸ Pause"}</button> | <input type="checkbox" checked={soundEnabled} onChange={(e) => setSoundEnabled( (e.target as HTMLInputElement).checked)} /> Sounds enabled</div>
				<div className="grid-ctr">
					<div className="grid-lhs">
						<Clock seconds={ totalSecs } />
					</div>
					<div className="grid-rhs">
						<h2>Participants</h2>
						{gamePin && <p className="join">Join on mobile: <span className="ul">nick.clearwater.id.au/join</span> and enter code <span className="code">{gamePin}</span>.</p>}
						{playerStates.map( (p) =>
							<Participant pName={p.name} state={p.state} missed={p.total_missed} timeCritical={timeCritical} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default App;
