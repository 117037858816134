import { useEffect } from 'react';

export default function Clock({seconds}: { seconds: number }) {

	useEffect( () => {
		fetch("/clock.svg")
		 .then((e) => e.text())
		 .then((txt) => { document.getElementById('clock-target')!.innerHTML = txt });
	}, []);

	useEffect( () => {
		let hand_elem = document.getElementById('hand');
		if(hand_elem) {
			hand_elem.style.transform = 'rotate(' + (seconds/60) + 'turn)';
		}
	}, [seconds]);

	return (
		<div id="clock-target" className="clock"></div>
	);
}
