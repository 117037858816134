import { useState } from 'react';

enum LoadState {
	EnterPin,
	LoadingPin,
	PinError,
	EnterName,
	LoadingName,
	ErrorLoadingName,
	InGame,
};

export default function JoinApp() {
	const [st, setState] = useState(LoadState.EnterPin);
	const [err, setError] = useState("");
	const [name, setName] = useState("");
	const [pin, setPin] = useState("");
	// for LoadState == LoadingPin, these are the other missing players we
	// might want to re-connect as
	const [playerOptions, setPlayerOptions] = useState<string[]>([]);
	// actual game state
	const [currentRound, setCurrentRound] = useState(0);
	const [currentRoundDone, setCurrentRoundDone] = useState(false);

	function doJoin(e: any) {
		if(e) { e.preventDefault(); }
		setState(LoadState.LoadingPin);
		setError("");
		setPlayerOptions([]);

		fetch("/api/join1?pin=" + pin)
		 .then( (r) => r.json() )
		 .then( (j) => {
			if(typeof j != 'number' && j.hasOwnProperty('players')) {
				// In this case the PIN is OK
				setPlayerOptions(j.players);
				setState(LoadState.EnterName);
			} else {
				setState(LoadState.PinError);
				if(typeof j === 'number') {
					setError("Network error");
				} else {
					setError(j.err);
				}
			}
		 } )
		 .catch(() => { setState(LoadState.PinError); setError("Network exception/error"); });
	}

	function markSafe() {
		setCurrentRoundDone(!currentRoundDone);
		fetch("/api/mark_safe?" + new URLSearchParams( {pin: pin, name: name} ), {method: 'POST'});
	}

	function doHeartbeat(e: any) {
		if(e) { e.preventDefault(); }
		// This function is both 'join2' and 'heartbeat', because there is no
		// stateful authentication
		setState(LoadState.LoadingName);
		setError("");

		fetch("/api/join2?" + new URLSearchParams( {pin: pin, name: name} ))
		 .then( (r) => r.json() )
		 .then( (j) => {
			if(typeof j != 'number' && j.hasOwnProperty('round')) {
				// We are authenticated
				setState(LoadState.InGame);
				setCurrentRound(0+j.round);
				setCurrentRoundDone(!!j.is_safe);
				// We can now set up the next heartbeat
				setTimeout(doHeartbeat, 1000*j.next_heartbeat_sec);
			} else {
				setState(LoadState.PinError);
				if(typeof j === 'number') {
					setError("Network error");
				} else {
					setError(j.err);
				}
			}
		 } )
		 .catch(() => { setState(LoadState.PinError); setError("Network exception/error"); });
		 // note the catch drops us all the way back to the start to be robust
		 // if something goes wrong
	}

	if(st === LoadState.EnterPin || st === LoadState.PinError) {
		return (<div className="join-wrapper">
			<p>Enter your Centurion Code below:</p>
			<form onSubmit={(e) => doJoin(e)}>
			<input type="text" id="pin" value={pin} onChange={(e) => setPin(e.target.value)} />
			{err && <p className="error">{err}</p>}
			<input type="submit" value="Join" />
			</form>
		</div>);
	} else if(st === LoadState.LoadingPin) {
		return (<div className="join-wrapper">
			<p>Checking...</p>
		</div>);
	} else if(st === LoadState.EnterName || st === LoadState.ErrorLoadingName) {
		return (<div className="join-wrapper">
			<p>Joining session <span className="boxed">{pin}</span></p>
			<p>Name:</p>
			<form onSubmit={(e) => doHeartbeat(e)}>
			<input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
			{err && <p className="error">{err}</p>}
			<input type="submit" value="Join" />
			</form>
		</div>);
	} else {
		return (<div className={"join-wrapper" + (currentRoundDone ? " safe" : " unsafe") }>
			<h1>ROUND {currentRound}</h1>
			{ currentRoundDone ? <p>You’re safe for now.</p> :
			<p><input type="submit" value="Mark complete" onClick={(e) => markSafe()} /></p>}
		</div>);
	}
}
